<template>
    <v-app>
        <div class="row">
            <div class="col-xl-12 col-lg-12 order-lg-12 order-xl-1">
                <KTPortlet v-bind:title="'Checkout'">
                    <template v-slot:toolbar></template>
                    <template v-slot:body v-if="cart">
                        <div class="kt-invoice__body">
                            <div class="kt-invoice__container">
                                <div class="row">
                                    <div class="col-md-4 order-md-2 mb-4">
                                        <h4 class="d-flex justify-content-between align-items-center mb-3">
                                            <span class="text-muted">Cart Items</span>
                                            <span
                                                class="badge badge-secondary badge-pill"
                                            >{{cart.membership_items.length + cart.domain_items.length}}</span>
                                        </h4>
                                        <ul class="list-group mb-3">
                      <span v-if="cart.membership_items.length>0">
                        <li
                            v-for="item in cart.membership_items"
                            :key="item.id"
                            class="list-group-item d-flex justify-content-between lh-condensed"
                        >
                          <div>
                            <h6
                                class="my-0"
                                v-if="item.organisation_title"
                            >{{ item.organisation_title }}&nbsp;({{item.item_type}})</h6>
                            <h6
                                class="my-0"
                                v-else
                            >{{ item.website_title }}&nbsp;({{item.item_type}})</h6>

                            <small class="text-muted">{{item.terms}} month</small>
                          </div>
                          <span class="text-muted">
                           {{store.currency_symbol}}{{item.actual_price}}
                            <span
                                @click="deleteCartItem(item.id)"
                                class="ml-5"
                            >
                              <i class="fas fa-trash text-danger"></i>
                            </span>
                          </span>
                        </li>
                      </span>

                                            <span v-if="cart.domain_items.length>0">
                        <li
                            v-for="item in cart.domain_items"
                            :key="item.id"
                            class="list-group-item d-flex justify-content-between lh-condensed"
                        >
                          <div>
                            <h6 class="my-0">{{ item.domain_name }}&nbsp;({{item.item_type}})</h6>

                            <small class="text-muted">{{item.terms}} month</small>
                          </div>
                          <span class="text-muted">
                           {{store.currency_symbol}}{{item.actual_price}}
                            <span
                                @click="deleteCartItem(item.id)"
                                class="ml-5"
                            >
                              <i class="fas fa-trash text-danger"></i>
                            </span>
                          </span>
                        </li>
                      </span>

                                            <span v-if="cart.other_items.length>0">
                        <li
                            v-for="item in cart.other_items"
                            :key="item.id"
                            class="list-group-item d-flex justify-content-between lh-condensed"
                        >
                          <div>
                            <h6
                                class="my-0"
                            >{{ item.title }}&nbsp;({{item.item_type}})</h6>

                              <!-- <small class="text-muted">{{item.terms}} month</small> -->
                          </div>
                          <span class="text-muted">
                           {{store.currency_symbol}}{{item.item_price}}
                            <span
                                @click="deleteCartItem(item.id)"
                                class="ml-5"
                            >
                              <i class="fas fa-trash text-danger"></i>
                            </span>
                          </span>
                        </li>
                      </span>

                                            <span>
                        <li class="list-group-item d-flex justify-content-between">
                          <strong>Sub Total</strong>
                          <strong>{{store.currency_symbol}}{{cart.sub_total_amount}}</strong>
                        </li>
                      </span>
                                            <span v-if="cart.coupon_code">
                        <li class="list-group-item d-flex justify-content-between bg-light">
                          <div class="text-success">
                            <h6 class="my-0">Promo code</h6>
                            <small>[{{cart.coupon_code}}]</small>
                          </div>
                          <span class="text-success">
                            -
                            <strong>{{store.currency_symbol}}{{cart.discount_amount}}</strong>
                            <span @click="removeDiscount(cart.id)" class="ml-5">
                              <i class="fas fa-trash text-danger"></i>
                            </span>
                          </span>
                        </li>
                      </span>
                                            <span>
                        <li class="list-group-item d-flex justify-content-between">
                          <strong>VAT {{(store.tax_percentage)* 100}}%</strong>
                          <strong>{{store.currency_symbol}}{{cart.tax_amount}}</strong>
                        </li>
                      </span>
                                            <span>
                        <li class="list-group-item d-flex justify-content-between">
                          <strong>Total Amount</strong>
                          <strong>{{store.currency_symbol}}{{cart.total_amount}}</strong>
                        </li>
                      </span>
                                        </ul>

                                        <form class="card p-2" v-if="!cart.coupon_code && cart.total_amount!=0">
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    v-model="coupon_code"
                                                    class="form-control"
                                                    placeholder="Promo code"
                                                />
                                                <strong>
                                                    <p class="text-danger" v-if="!coupon_code_status">{{message}}</p>
                                                </strong>
                                                <div class="input-group-append">
                                                    <button
                                                        type="button"
                                                        @click.prevent="applyDiscountCode(cart.id)"
                                                        class="btn btn-secondary"
                                                    >Redeem
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        {{user.country_id!=155}}
                                        <v-col
                                            cols="12"
                                            :class="!payer_address?'disabledbutton':''"
                                            v-if="user.country_id!=null && user.country_id!=155 && cart.total_amount!=0"
                                        >
                                            <PayPal
                                                :amount="cart.total_amount"
                                                :button-style="paypal.paypal_style"
                                                :currency="paypal.currency"
                                                :client="paypal.credentials"
                                                env="sandbox"
                                                v-on:payment-authorized="paymentAuthorized"
                                                v-on:payment-completed="paymentCompleted"
                                                v-on:payment-cancelled="paymentCancelled"
                                                class="offline-payments"
                                            ></PayPal>
                                        </v-col>
                                    </div>
                                    <div class="col-md-8 order-md-1">
                                        <h4 class="mb-3">Billing address</h4>
                                        <form class="needs-validation" novalidate>
                                            <strong>
                                                <p v-if="!payer_address">
                                                    <span class="text-black">Select your billing address</span> or click
                                                    <a href="#" @click.once="addAddress()">here to add new.</a>
                                                </p>
                                                <addresses ref="userAddress" @update="getAddressesByUser"></addresses>
                                            </strong>
                                            <v-radio-group v-model="payer_address" row>
                                                <v-radio :value="item" v-for="(item,i) in addresses" :key="i">
                                                    <template v-slot:label>
                                                        <v-card outlined max-width="200">
                                                            <p class="p-3">{{ item }}</p>
                                                        </v-card>
                                                    </template>
                                                </v-radio>
                                            </v-radio-group>

                                            <h4 class="mb-3">Payment Method</h4>

                                            <div class="d-block my-3" :class="!payer_address?'disabledbutton':''">
                                                <v-row class="payment-list" v-if="cart.total_amount!=0">
                                                    <v-col cols="4"
                                                           v-if="user.country_id==null || user.country_id==155">
                                                        <a
                                                            href="#"
                                                            class
                                                            @click="esewaPayment(cart.id, cart.tax_amount, cart.total_amount)"
                                                        >
                                                            <b-img
                                                                src="assets/media/payment-options/esewa-logo.png"
                                                                fluid
                                                                alt="Responsive image"
                                                            ></b-img>
                                                        </a>
                                                    </v-col>
                                                    <v-col cols="4"
                                                           v-if="user.country_id==null || user.country_id==155">
                                                        <a href="#" class
                                                           @click="khaltiPayment(cart.id, cart.total_amount)">
                                                            <b-img
                                                                src="assets/media/payment-options/khalti_checkout.png"
                                                                fluid
                                                                alt="Responsive image"
                                                            ></b-img>
                                                        </a>
                                                    </v-col>
                                                    <v-col cols="4"  v-if="user.country_id==null || user.country_id==155">
                                                        <a
                                                            href="#"
                                                            class="offline-payments"
                                                            @click.prevent="openOfflinePayDialog()"
                                                        >
                                                            <b-img
                                                                src="assets/media/payment-options/offline-payments.png"
                                                                fluid
                                                                alt="Responsive image"
                                                            ></b-img>
                                                        </a>
                                                    </v-col>

                                                    <v-col cols="12"
                                                           v-if="user.country_id!=null && user.country_id!=155">
                                                        <h4 class="mb-3">Credit/Debit Card (International)</h4>
                                                        <stripe-elements
                                                            ref="elementsRef"
                                                            :pk="stripe.publishableKey"
                                                            :amount="cart.total_amount"
                                                            @token="tokenCreated"
                                                            @loading="loading = $event"
                                                        ></stripe-elements>
                                                    </v-col>
                                                    <v-col cols="12" class="text-center"
                                                           v-if="user.country_id!=null && user.country_id!=155">
                                                        <button class="btn btn-primary" @click="stripeCheckout">PAY WITH
                                                            STRIPE
                                                        </button>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-else>
                                                    <v-col cols="12">
                                                        <a
                                                            href="#"
                                                            class="btn btn-primary btn-coupon-code text-white"
                                                            @click="freeCheckout()"
                                                        >Checkout Now</a>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <hr class="mb-4"/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="body-1" v-if="cart.total_amount!=0">
                            For Bank deposit or Cash Payment
                            Please email deposit receipt at contact@yelko.com
                        </p>
                        <!--            <p-->
                        <!--              v-if="cart.total_amount!=0"-->
                        <!--              class="subtitle-1"-->
                        <!--            >On Paypal checkout you will be charged 10% processing cost of total amount.</p>-->
                    </template>
                    <template v-slot:body v-else>
                        <div class="text-center">
                            <h4>Cart is empty</h4>
                        </div>
                    </template>
                </KTPortlet>
            </div>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog scrollable v-model="offlineDialog" persistent max-width="600">

            <v-card>
                <v-card-title class="headline">Confirm Offline Payment</v-card-title>
                <v-card-text>
                    <br>
                    <p>
                        To make an offline payment, please use the bank details provided to make a deposit.
                        Alternatively, you can also cash in our office.
                    </p>
                    <p>
                        It may take upto 2-3 business days to approve your transaction once we receive the payment.
                    </p>
                    <p>
                        If you have a domain purchase under this cart, we strongly recommend to use digital payment
                        gateway to secure your domain. Paying offline will delay in domain registration and may not be
                        available during the time.
                    </p>
                    <br>

                    <h4>Siddhartha Bank Limited</h4>
                    <p>New Road Branch</p>
                    <p>Account Name : <strong>Yelko Web Pvt Ltd.</strong></p>
                    <p>Account Number : <strong>00415243292</strong></p>
                    <br>
                    <p><strong>Instruction :</strong>

                        After Deposit, Please email us the deposit Slip to <a href="mailto:contact@yelko.com">contact@yelko.com</a>
                        or call us at <a href="tel:9823428000">9823428000</a>
                    </p>
                    <!--                        <p>Account Name : <strong>Yelko Web Pvt Ltd.</strong></p>-->

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeOfflinePayDialog()">Disagree</v-btn>
                    <v-btn color="green darken-1" text @click="payOffline(cart.id)">Agree</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
    import {mapState, mapActions, mapMutations} from "vuex";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import CartService from "@/services/cart/CartService";
    import OptionService from "@/services/option/OptionService";
    import UserAddressService from "@/services/user/UserAddressService";
    import PayPal from "vue-paypal-checkout";
    import {StripeElements} from "vue-stripe-checkout";
    import Addresses from "./Address";

    const cartService = new CartService();
    const userAddressService = new UserAddressService();
    const optionService = new OptionService();

    export default {
        components: {
            KTPortlet,
            PayPal,
            StripeElements,
            Addresses
        },

        data() {
            return {
                payer_address: null,
                paymentSetting: {},
                addresses: [],
                user: null,
                overlay: false,
                coupon_code: null,
                coupon_code_status: true,
                message: null,
                paypalAuthorizationData: null,
                offlineDialog: false,
                paypal: {
                    currency: process.env.VUE_APP_PAYPAL_CURRENCY,
                    credentials: {
                        sandbox: process.env.VUE_APP_PAYPAL_CLIENT_ID
                    },
                    paypal_style: {
                        size: "responsive",
                        color: "gold",
                        height: 55
                    }
                },
                stripe: {
                    loading: false,
                    publishableKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
                    successUrl: process.env.VUE_APP_STRIPE_SUCCESS_URL,
                    cancelUrl: process.env.VUE_APP_STRIPE_ERROR_URL
                }
            };
        },
        created() {
            this.getAddressesByUser();
            this.getPaymentOptions();
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: "Cart "}]);
            this.user = this.$store.getters.currentUser;
        },
        beforeUpdate() {
            let queries = this.$route.query;
            if (this.cart && queries && queries.oid && queries.refId && queries.amt) {
                queries.cart_id = this.cart.id;
                this.esewaVerfiy(queries);
            }
        },
        computed: {
            ...mapState({
                cart: state => state.cart.cart
            }),

            store() {
                return this.$store.getters.currentUser.store_config;
            },
        },
        watch: {
            coupon_code: function (val) {
                this.coupon_code = val.toUpperCase();
            }
        },
        methods: {
            ...mapMutations(["SET_CART"]),
            ...mapActions([
                "GET_CART",
                "DELETE_CART_ITEM",
                "APPLY_DISCOUNT_CODE",
                "REMOVE_DISCOUNT"
            ]),
            openOfflinePayDialog() {
                this.offlineDialog = true;
            },
            closeOfflinePayDialog() {
                this.offlineDialog = false;
            },

            getPaymentOptions() {
                optionService
                    .paymentSetting()
                    .then(res => {
                        this.paymentSetting = res.data;
                    })
                    .catch(error => {
                    });
            },
            getAddressesByUser() {
                this.addresses = [];
                userAddressService
                    .getAddressesByUser()
                    .then(response => {
                        let temp = [];
                        response.data.forEach(element => {
                            let addressLine = element.address_line_1
                                ? element.address_line_1 + ", "
                                : null;
                            let area = element.area ? element.area + ", " : null;
                            let city = element.city ? element.city + ", " : null;
                            let state = element.state ? element.state : null;
                            let address = addressLine + area + city + state;
                            this.addresses.push(address);
                        });
                    })
                    .catch(error => {
                    });
            },
            addAddress() {
                this.$refs.userAddress.openDialog();
            },
            deleteCartItem(id) {
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                this.DELETE_CART_ITEM(id)
                                    .then(response => {
                                        this.$snotify.success("Item removed from cart");
                                    })
                                    .catch(error => {
                                        this.$snotify.error("Error occured");
                                    });
                            }
                        }
                    }
                )

            },
            applyDiscountCode(cartId) {
                cartService
                    .applyDiscountCode(cartId, this.coupon_code)
                    .then(response => {
                        if (response.data.status === "ERROR") {
                            this.coupon_code_status = false;
                            this.message = response.data.message;
                            setTimeout(() => {
                                this.coupon_code_status = true;
                                this.message = null;
                            }, 4000);
                        } else {
                            this.coupon_code = null;
                            this.$snotify.success("Discount code applied");
                            this.SET_CART(response.data.cart);
                        }
                    })
                    .catch(error => {
                    });
            },
            removeDiscount(id) {
                this.REMOVE_DISCOUNT(id)
                    .then(response => {
                        this.$snotify.success("Discount code removed");
                    })
                    .catch(error => {
                        this.$snotify.error("Error occured");
                    });
            },
            validatePayerAddress() {
                this.$v.$touch();
                if (!this.$v.payer_address.$error) {
                    return true;
                } else {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                    return false;
                }
            },
            // offline payemnt
            payOffline: function (cartId) {
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                let data = {payer_address: this.payer_address};
                                this.overlay = true;
                                cartService
                                    .payOffline(cartId, data)
                                    .then(res => {
                                        this.$snotify.success("Your request has been processed");
                                        this.SET_CART(null);
                                        this.overlay = false;
                                        this.$router.push({name: "payment-success"});
                                    })
                                    .catch(err => {
                                    });
                            }
                        }
                    }
                )

            },
            // khalti payment

            khaltiPayment(cartId, amount) {
                amount = amount.replace(',', '');
                let notify = this.$snotify;
                let mutation = this.$store;
                let route = this.$router;
                let loader = this.overlay;
                let address = this.payer_address;
                var config = {
                    // replace the publicKey with yours
                    publicKey: process.env.VUE_APP_KHALTI_PUBLIC_KEY,
                    productIdentity: cartId,
                    productName: "YELKO_PURCHASE",
                    productUrl: process.env.VUE_APP_KHALTI_PRODUCT_URL,
                    eventHandler: {
                        onSuccess(payload) {
                            this.overlay = true;
                            cartService
                                .paymentVerify(payload)
                                .then(response => {
                                    (response.data.payer_address = address),
                                        cartService
                                            .khalti(cartId, response.data)
                                            .then(response => {
                                                notify.success("Payment has been completed");
                                                mutation.commit("SET_CART", null);
                                                this.overlay = false;
                                                route.push({name: "payment-success"});
                                            })
                                            .catch(error => {
                                            });
                                })
                                .catch(error => {
                                });
                            // hit merchant api for initiating verfication
                        },
                        onError(error) {
                        },
                        onClose() {
                        }
                    }
                };

                var checkout = new KhaltiCheckout(config);
                checkout.show({amount: parseFloat(amount) * 100});
            },

            esewaPayment: function (cartId, tax, total) {
                total = total.replace(',', '');
                tax = tax.replace(',', '');
                window.localStorage.setItem("payer_address", this.payer_address);
                let successUrl = process.env.VUE_APP_ESEWA_SUCCESS_URL;
                let errorUrl = process.env.VUE_APP_ESEWA_ERROR_URL;
                var path = process.env.VUE_APP_ESEWA_PAYMENT_URL;
                var params = {
                    amt: (parseFloat(total) - parseFloat(tax)),
                    psc: 0,
                    pdc: 0,
                    txAmt: tax,
                    tAmt: total,
                    pid: "YELKO-" + cartId,
                    scd: process.env.VUE_APP_ESEWA_MERCHANT,
                    su: successUrl,
                    fu: errorUrl
                };

                var form = document.createElement("form");
                form.setAttribute("method", "POST");
                form.setAttribute("action", path);

                for (var key in params) {
                    var hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    hiddenField.setAttribute("value", params[key]);
                    form.appendChild(hiddenField);
                }

                document.body.appendChild(form);
                form.submit();
            },

            esewaVerfiy(queries) {
                let user = this.$store.getters.currentUser;
                let data = queries;
                data.payer_name = user.first_name + " " + user.last_name;
                data.payer_email = user.email;
                data.payer_phone = user.phone;
                data.payer_address = window.localStorage.getItem("payer_address");
                this.overlay = true;
                cartService
                    .esewa(data.cart_id, data)
                    .then(response => {
                        if (response) {
                            window.localStorage.removeItem("payer_address");
                            this.$snotify.success("Payment has been completed");
                            this.SET_CART(null);
                            let query = {};
                            this.$router.replace({query});
                            this.overlay = false;
                            this.$router.push({name: "payment-success"});
                        } else {
                            this.$snotify.error("Error occured");
                        }
                    })
                    .catch(error => {
                        this.$snotify.error("Error occured");
                    });
            },
            paymentAuthorized(event) {
                this.paypalAuthorizationData = event;
            },
            paymentCompleted(event) {
                let data = {
                    payment_complete: event,
                    payment_authorization: this.paypalAuthorizationData,
                    payer_address: this.payer_address
                };
                this.overlay = true;
                cartService
                    .paypal(this.cart.id, data)
                    .then(response => {
                        if (response) {
                            this.$snotify.success("Payment has been completed");
                            this.SET_CART(null);
                            this.overlay = false;
                            this.$router.push({name: "payment-success"});
                        } else {
                            this.$snotify.error("Error occured");
                        }
                    })
                    .catch(error => {
                    });
            },
            paymentCancelled(event) {
            },
            stripeCheckout() {
                // this.$refs.checkoutRef.redirectToCheckout();
                this.$refs.elementsRef.submit();
            },
            tokenCreated(token) {
                // for additional charge objects go to https://stripe.com/docs/api/charges/object
                let value = {
                    source: token.id,
                    amount: this.cart.total_amount,
                    token: token // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
                    // optional description that will show up on stripe when looking at payments
                };
                this.sendTokenToServer(value);
            },
            sendTokenToServer(value) {
                let user = this.$store.getters.currentUser;
                let userData = {};
                userData.payer_name = user.first_name + " " + user.last_name;
                userData.payer_email = user.email;
                userData.payer_mobile = user.phone;
                value.user = userData;
                value.payer_address = this.payer_address;
                this.overlay = true;
                cartService
                    .stripe(this.cart.id, value)
                    .then(response => {
                        if (response) {
                            this.$snotify.success("Payment has been processed");
                            this.SET_CART(null);
                            this.overlay = false;
                            // this.$router.push({name: "payment-success"});
                        } else {
                            this.$snotify.error("Error occured");
                        }
                    })
                    .catch(error => {
                        this.$snotify.error("Error occured");
                    });
            },
            freeCheckout() {
                let data = {};
                let user = this.$store.getters.currentUser;
                data.payer_name = user.first_name + " " + user.last_name;
                data.payer_email = user.email;
                data.payer_phone = user.phone;
                data.payer_address = this.payer_address;
                cartService
                    .free(this.cart.id, data)
                    .then(response => {
                        if (response) {
                            this.$snotify.success("Payment has been completed");
                            this.SET_CART(null);
                            this.overlay = false;
                            this.$router.push({name: "payment-success"});
                        } else {
                            this.$snotify.error("Error occured");
                        }
                    })
                    .catch(error => {
                        this.$snotify.error("Error occured");
                    });
            }
        }
    };
</script>

<style>
    .payment-list a {
        display: block;
        position: relative;
        padding: 10px;
        border: 1px solid #e5f3ff;
        border-radius: 5px;
        text-align: center;
        min-height: 74px;
        transition: all 300ms;
    }

    .payment-list a:hover {
        background: #e5f3ff;
    }

    .payment-list a > img {
        width: 130px;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .payment-list .offline-payments > img {
        width: 200px;
    }

    .disabledbutton {
        pointer-events: none;
        opacity: 0.4;
    }
</style>
