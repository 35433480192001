import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;

export default class OptionService {

      paymentSetting() {
            let url = API_URL + 'user/options/payment/settings';
            return apiService.get(url);
      }
}
