import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class organisationAddress {
    getAllAddresses() {
        let url = API_URL + 'user/addresses'
        return apiService.get(url);
    }
    getAddress(value) {
        let url = API_URL + 'user/address/search?key=' + value
        return apiService.query(url);
    }

    create(data) {
        data.type = data.type.toLowerCase();
        let url = API_URL + 'user/address/create'
        return apiService.post(url, data);
    }

    update(id, data) {
        data.type = data.type.toLowerCase();
        let url = API_URL + 'user/address/'+ id +'/update'
        return apiService.put(url, data);
    }

    delete(id) {
        let url = API_URL + 'user/address/'+ id + '/delete'
        return apiService.delete(url);
    }

    getAddressesByUser(){
        let url = API_URL + 'user/all/addresses'
        return apiService.get(url);
    }
}
